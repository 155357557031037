export const studentYear = 19;
export const startRollNumber = 8001;
export const clubs = [
  "Dance Club",
  "CCA",
  "Darpan",
  "Debsoc",
  "Enteract",
  "EDC",
  "Literary Circle",
  "GLUG",
  "IEEE",
  "ISTE",
  "MNTC",
  "Music Club",
  "Prakriti",
  "Quizinc",
  "Radio Nitroz",
  "RECursion",
  "SAE",
  "Spic Macay",
  "Strokes",
  "Evolvere",
  "BMEP",
  "NSS",
  "CHESS",
  "ECESS",
  "MESA",
  "TnP Cell",
  "SAI Cell",
  "IIM Student Chapter",
  "Basketball Team",
  "Cricket Team",
  "Football Team",
  "Table Tennis Team",
  "Team NDORS",
  "Team Vortex",
  "Team Robocon",
];

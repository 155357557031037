export const BranchData = {
  BT: {
    additionalRollNumbers: ["18BT8022", "18BT8037", "18BT8045"],
    start: 8001,
    end: 8059,
    name: "Biotechnology",
    code: "BT",
  },
  CE: {
    additionalRollNumbers: [],
    start: 8001,
    end: 8068,
    name: "Civil Engineering",
    code: "CE",
  },
  CH: {
    additionalRollNumbers: [],
    start: 8001,
    end: 8067,
    name: "Chemical Engineering",
    code: "CH",
  },
  CS: {
    additionalRollNumbers: [
      "18CS8006",
      "18CS8034",
      "18CS8038",
      "18CS8043",
      "18CS8095",
    ],
    start: 8001,
    end: 8188,
    name: "Computer Science and Engineering",
    code: "CS",
  },
  EC: {
    additionalRollNumbers: [],
    start: 8001,
    end: 8089,
    name: "Electronics and Communication Engineering",
    code: "EC",
  },
  ME: {
    additionalRollNumbers: ["18ME8151", "18ME8155"],
    start: 8001,
    end: 8174,
    name: "Mechanical Engineering",
    code: "ME",
  },
  EE: {
    additionalRollNumbers: [],
    start: 8001,
    end: 8093,
    name: "Electrical Engineering",
    code: "EE",
  },
  MM: {
    additionalRollNumbers: [],
    start: 8001,
    end: 8065,
    name: "Metallurgical and Materials Engineering",
    code: "MM",
  },
  "DD-BT": {
    additionalRollNumbers: [],
    start: 1001,
    end: 1004,
    name: "Biotechnology-Dual Degree",
    code: "BT",
  },
  "DD-CH": {
    additionalRollNumbers: [],
    start: 1001,
    end: 1005,
    name: "Chemical Engineering-Dual Degree",
    code: "CH",
  },
  CY: {
    additionalRollNumbers: [],
    start: 1001,
    end: 1008,
    name: "Integrated M.Sc. in Chemistry",
    code: "CY",
  },
};
